* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  width: 100%;
}

h1 {
  font-size: 64px;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

h2 {
  font-size: 36px;
  color: #ddd;
  font-weight: 500;
  /* text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000; */
}

a {
  color: #ccc;
  text-decoration: none;
  transition: color 0.4s;
}

a:hover {
  color: #fff;
  transition: color 0.4s;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* End HTML5 tag stuff */

#root {
  position: relative;
}

#root,
#initials-screen {
  height: 100%;
  width: 100%;
}

#initials-screen {
  background: #000;
  color: #fff;
  position: absolute;
  z-index: 0;
}

@keyframes too-bright {
  0% {
    filter: brightness(100%) invert(0%);
  }
  75% {
    filter: brightness(0) invert(75%);
  }
  100% {
    filter: brightness(0) invert(100%);
  }
}

@keyframes scale-up {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}

/* #initials {
  transform: scale(0.1);
  animation: scale-up 10s ease-in-out 1 forwards,
    too-bright 10s ease-in-out 10s 1 forwards;
} */

@keyframes brigthen {
  from {
    filter: brightness(0%);
  }
  to {
    filter: brightness(100%);
  }
}

#stars-video {
  filter: brightness(0%);
  animation: brigthen 7s 1 forwards, too-bright 10s ease-in-out 10s 1 forwards;
}

#initials-screen > #centered {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  margin: -50px 0 0 0;
  animation: fade-in 3s;
}

#centered h1 {
  font-family: "Parisienne", cursive;
  font-size: 64px;
  line-height: 64px;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}

progress::-webkit-progress-bar {
  margin: 0;
  padding: 0;
  background: #999;
  color: #999;
  height: 4px;
  border-radius: 20px;
}

progress::-webkit-progress-value {
  margin: 0;
  padding: 0;
  background: #fff;
  color: #fff;
  height: 4px;
  border-radius: 20px;
}

progress::-moz-progress-bar {
  margin: 0;
  padding: 0;
  background: #999;
  color: #999;
  height: 4px;
  border-radius: 20px;
}

progress {
  margin: 0;
  padding: 0;
  background-color: #fff;
  height: 4px;
  border-radius: 20px;
}

#main-content {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 600px) {
  #main-content {
    flex-direction: row;
  }
}

#main-content > aside,
#main-content > main {
  height: 100%;
  width: 100%;
}

aside {
  flex-basis: 38%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

main {
  flex-basis: 62%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#first-video {
  opacity: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  position: absolute;
  z-index: 1;
}

#menu-screen {
  opacity: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  position: absolute;
  z-index: 2;
  background-image: url(./blackbeach.jpg);
}

nav {
  height: 80px;
}

nav > a {
  display: inline-block;
  padding: 20px 20px;
  color: #fff;
}

/* The animation code */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.video {
  left: 0;
  right: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(0deg);
  position: absolute;
}

#main-video {
  z-index: -1;
}

#transition-video {
  z-index: 1;
  opacity: 0;
}

#transparency {
  z-index: 4;
}

#zu {
  z-index: 2;
  width: 100%;
}

#zu2 {
  z-index: 3;
  width: 100%;
}

#speaker {
  width: 50px;
  cursor: pointer;
}

#text-container {
  color: white;
  width: 400px;
  text-align: center;
  z-index: 3;
  padding: 5px 20px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.fade-out {
  animation: fade-out 2s ease-in-out;
  animation-fill-mode: forwards;
}

.fade-in {
  animation: fade-in 1s ease-in-out 1s;
  animation-fill-mode: forwards;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
